<template>
  <!-- Navbar -->
  <nav
    class="top-0 navbar navbar-expand-sm z-index-3"
    :class="isBlur ? isBlur : 'shadow-none my-3 w-100 mt-4'"
  >
    <div class="container-fluid justify-content-center text-center" :class="isBlur && 'px-1'">
      <router-link
        class="navbar-brand font-weight-bolder"
        :class="darkMode ? 'text-black' : 'text-white'"
        to="/home"
        >
        <img
          :src="logo"
          class="w-50 h-50"
        />
      </router-link
      >
      <button
        class="shadow-none navbar-toggler ms-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navigation"
        aria-controls="navigation"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="mt-2 navbar-toggler-icon">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </span>
      </button>
      <div
        id="navigation"
        class="pt-3 pb-2 collapse navbar-collapse w-100 py-lg-0"
      >
        <ul class="justify-content-start navbar-nav navbar-nav-hover">
          <li class="mx-2 nav-item dropdown dropdown-hover">

            <a
                id="dropdownMenuPages"
                role="button"
                class="text-md nav-link font-weight-bolder ps-2 justify-content-between align-items-center"
                :class="[darkModes]"
                aria-expanded="false"
                href="/mint"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag-heart" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5Zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0ZM14 14V5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1ZM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
              </svg>&nbsp;
              Mint
            </a>
          </li>
          <li class="mx-2 nav-item dropdown dropdown-hover">

            <a
                id="s"
                role="button"
                class="text-md nav-link font-weight-bolder ps-2 justify-content-between align-items-center"
                :class="[darkModes]"
                aria-expanded="false"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shield-lock" viewBox="0 0 16 16">
                <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z"/>
              </svg>&nbsp;
              Stake
            </a>
          </li>
          <li class="mx-2 nav-item">

            <a
                id="d"
                role="button"
                class="text-md nav-link font-weight-bolder ps-2 justify-content-between align-items-center"
                :class="[darkModes]"
                aria-expanded="false"
                href="https://docs.proxcity.xyz/"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-doc" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-7.839 9.166v.522c0 .256-.039.47-.117.641a.861.861 0 0 1-.322.387.877.877 0 0 1-.469.126.883.883 0 0 1-.471-.126.868.868 0 0 1-.32-.386 1.55 1.55 0 0 1-.117-.642v-.522c0-.257.04-.471.117-.641a.868.868 0 0 1 .32-.387.868.868 0 0 1 .471-.129c.176 0 .332.043.469.13a.861.861 0 0 1 .322.386c.078.17.117.384.117.641Zm.803.519v-.513c0-.377-.068-.7-.205-.972a1.46 1.46 0 0 0-.589-.63c-.254-.147-.56-.22-.917-.22-.355 0-.662.073-.92.22a1.441 1.441 0 0 0-.589.627c-.136.271-.205.596-.205.975v.513c0 .375.069.7.205.973.137.271.333.48.59.627.257.144.564.216.92.216.357 0 .662-.072.916-.216.256-.147.452-.356.59-.627.136-.274.204-.598.204-.973ZM0 11.926v4h1.459c.402 0 .735-.08.999-.238a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.59-.68c-.263-.156-.598-.234-1.004-.234H0Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.141 1.141 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082H.79V12.57Zm7.422.483a1.732 1.732 0 0 0-.103.633v.495c0 .246.034.455.103.627a.834.834 0 0 0 .298.393.845.845 0 0 0 .478.131.868.868 0 0 0 .401-.088.699.699 0 0 0 .273-.248.8.8 0 0 0 .117-.364h.765v.076a1.268 1.268 0 0 1-.226.674c-.137.194-.32.345-.55.454a1.81 1.81 0 0 1-.786.164c-.36 0-.664-.072-.914-.216a1.424 1.424 0 0 1-.571-.627c-.13-.272-.194-.597-.194-.976v-.498c0-.379.066-.705.197-.978.13-.274.321-.485.571-.633.252-.149.556-.223.911-.223.219 0 .421.032.607.097.187.062.35.153.489.272a1.326 1.326 0 0 1 .466.964v.073H9.78a.85.85 0 0 0-.12-.38.7.7 0 0 0-.273-.261.802.802 0 0 0-.398-.097.814.814 0 0 0-.475.138.868.868 0 0 0-.301.398Z"/>
              </svg>&nbsp;
              Docs
            </a>
          </li>
          <li class="mx-2 nav-item">

            <a
                id="dropdownMenuPages"
                role="button"
                class="text-md nav-link font-weight-bolder ps-2 justify-content-between align-items-center"
                :class="[darkModes]"
                aria-expanded="false"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-coin" viewBox="0 0 16 16">
                <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
              </svg>&nbsp;
              Token
            </a>
          </li>
          <li class="mx-2 nav-item">

            <a
                id="dropdownMenuPages"
                role="button"
                class="text-md nav-link font-weight-bolder ps-2 justify-content-between align-items-center"
                :class="[darkModes]"
                aria-expanded="false"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-controllert" viewBox="0 0 16 16">
                <path d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z"/>
                <path d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729c.14.09.266.19.373.297.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466.206.875.34 1.78.364 2.606.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527-1.627 0-2.496.723-3.224 1.527-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.34 2.34 0 0 1 .433-.335.504.504 0 0 1-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a13.748 13.748 0 0 0-.748 2.295 12.351 12.351 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.354 12.354 0 0 0-.339-2.406 13.753 13.753 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27-1.036 0-2.063.091-2.913.27z"/>
              </svg>&nbsp;
              Play
            </a>
          </li>
<!--          <li class="mx-2 nav-item dropdown dropdown-hover">-->

<!--            <a-->
<!--              id="dropdownMenuPages"-->
<!--              role="button"-->
<!--              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"-->
<!--              :class="[darkModes]"-->
<!--              data-bs-toggle="dropdown"-->
<!--              aria-expanded="false"-->
<!--            >-->
<!--              Pages-->
<!--              <img-->
<!--                :src="downArrWhite"-->
<!--                alt="down-arrow"-->
<!--                class="arrow ms-1"-->
<!--                :class="darkMode ? 'd-none' : 'd-lg-block d-none'"-->
<!--              />-->
<!--              <img-->
<!--                :src="downArrBlack"-->
<!--                alt="down-arrow"-->
<!--                class="arrow ms-1 d-block"-->
<!--                :class="darkMode ? 'd-lg-block' : 'd-lg-none'"-->
<!--              />-->
<!--            </a>-->
<!--            <div-->
<!--              class="p-3 mt-0 dropdown-menu dropdown-menu-animation dropdown-xl border-radius-xl mt-lg-3"-->
<!--              aria-labelledby="dropdownMenuPages"-->
<!--            >-->
<!--              <div class="row d-none d-lg-block">-->
<!--                <div class="px-4 py-2 col-12">-->
<!--                  <div class="row">-->
<!--                    <div class="col-4 position-relative">-->
<!--                      <div-->
<!--                        class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                      >-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >dashboard</i-->
<!--                        >-->
<!--                        Dashboards-->
<!--                      </div>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Default' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Analytics</span>-->
<!--                      </router-link>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Sales' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Sales</span>-->
<!--                      </router-link>-->
<!--                      <div-->
<!--                        class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                      >-->
<!--                        <i-->
<!--                          class="material-icons text-dark opacity-6 me-2 text-md"-->
<!--                          >people</i-->
<!--                        >-->
<!--                        Users-->
<!--                      </div>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'New User' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">New User</span>-->
<!--                      </router-link>-->
<!--                      <hr class="vertical dark" />-->
<!--                    </div>-->
<!--                    <div class="col-4 position-relative">-->
<!--                      <div-->
<!--                        class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                      >-->
<!--                        <i-->
<!--                          class="material-icons text-dark opacity-6 me-2 text-md"-->
<!--                          >badge</i-->
<!--                        >-->
<!--                        Profile-->
<!--                      </div>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'All Projects' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Projects</span>-->
<!--                      </router-link>-->
<!--                      <div-->
<!--                        class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                      >-->
<!--                        <i-->
<!--                          class="material-icons text-dark opacity-6 me-2 text-md"-->
<!--                          >precision_manufacturing</i-->
<!--                        >-->
<!--                        Projects-->
<!--                      </div>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Timeline' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Timeline</span>-->
<!--                      </router-link>-->
<!--                      <hr class="vertical dark" />-->
<!--                    </div>-->
<!--                    <div class="col-4">-->
<!--                      <div-->
<!--                        class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                      >-->
<!--                        <i-->
<!--                          class="material-icons text-dark opacity-6 me-2 text-md"-->
<!--                          >account_balance</i-->
<!--                        >-->
<!--                        Account-->
<!--                      </div>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Settings' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Settings</span>-->
<!--                      </router-link>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Billing' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Billing</span>-->
<!--                      </router-link>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Invoice' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Invoice</span>-->
<!--                      </router-link>-->
<!--                      <div-->
<!--                        class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                      >-->
<!--                        <i-->
<!--                          class="material-icons text-dark opacity-6 me-2 text-md"-->
<!--                          >queue_play_next</i-->
<!--                        >-->
<!--                        Extra-->
<!--                      </div>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Pricing Page' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Pricing Page</span>-->
<!--                      </router-link>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'RTL' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">RTL Page</span>-->
<!--                      </router-link>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Widgets' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Widgets</span>-->
<!--                      </router-link>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; responsive &ndash;&gt;-->
<!--              <div class="d-lg-none">-->
<!--                <div-->
<!--                  class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                >-->
<!--                  <i class="material-icons text-dark opacity-6 me-2 text-md"-->
<!--                    >dashboard</i-->
<!--                  >-->
<!--                  Dashboards-->
<!--                </div>-->
<!--                <router-link-->
<!--                  :to="{ name: 'Default' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Default</router-link-->
<!--                >-->
<!--                <router-link-->
<!--                  :to="{ name: 'Sales' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Sales</router-link-->
<!--                >-->
<!--                <div-->
<!--                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                >-->
<!--                  <i class="material-icons text-dark opacity-6 me-2 text-md"-->
<!--                    >people</i-->
<!--                  >-->
<!--                  Users-->
<!--                </div>-->
<!--                <router-link to="#" class="dropdown-item border-radius-md"-->
<!--                  >New user</router-link-->
<!--                >-->
<!--                <div-->
<!--                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                >-->
<!--                  <i class="material-icons text-dark opacity-6 me-2 text-md"-->
<!--                    >badge</i-->
<!--                  >-->
<!--                  Profile-->
<!--                </div>-->
<!--                <router-link to="#" class="dropdown-item border-radius-md"-->
<!--                  >Projects</router-link-->
<!--                >-->
<!--                <div-->
<!--                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                >-->
<!--                  <i class="material-icons text-dark opacity-6 me-2 text-md"-->
<!--                    >precision_manufacturing</i-->
<!--                  >-->
<!--                  Projects-->
<!--                </div>-->
<!--                <router-link-->
<!--                  :to="{ name: 'Timeline' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Timeline</router-link-->
<!--                >-->
<!--                <div-->
<!--                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                >-->
<!--                  <i class="material-icons text-dark opacity-6 me-2 text-md"-->
<!--                    >account_balance</i-->
<!--                  >-->
<!--                  Account-->
<!--                </div>-->
<!--                <router-link-->
<!--                  :to="{ name: 'Settings' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Settings</router-link-->
<!--                >-->
<!--                <router-link-->
<!--                  :to="{ name: 'Billing' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Billing</router-link-->
<!--                >-->
<!--                <router-link-->
<!--                  :to="{ name: 'Invoice' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Invoice</router-link-->
<!--                >-->
<!--                <div-->
<!--                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                >-->
<!--                  <i class="material-icons text-dark opacity-6 me-2 text-md"-->
<!--                    >queue_play_next</i-->
<!--                  >-->
<!--                  Extra-->
<!--                </div>-->
<!--                <router-link-->
<!--                  :to="{ name: 'Pricing Page' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Pricing Page</router-link-->
<!--                >-->
<!--                <router-link-->
<!--                  :to="{ name: 'RTL' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >RTL Page</router-link-->
<!--                >-->
<!--                <router-link-->
<!--                  :to="{ name: 'Widgets' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Widgets</router-link-->
<!--                >-->
<!--              </div>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li class="mx-2 nav-item dropdown dropdown-hover">-->
<!--            <a-->
<!--              id="dropdownMenuAccount"-->
<!--              role="button"-->
<!--              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"-->
<!--              :class="[darkModes]"-->
<!--              data-bs-toggle="dropdown"-->
<!--              aria-expanded="false"-->
<!--            >-->
<!--              Authentication-->
<!--              <img-->
<!--                :src="downArrWhite"-->
<!--                alt="down-arrow"-->
<!--                class="arrow ms-1"-->
<!--                :class="darkMode ? 'd-none' : 'd-lg-block d-none'"-->
<!--              />-->
<!--              <img-->
<!--                :src="downArrBlack"-->
<!--                alt="down-arrow"-->
<!--                class="arrow ms-1 d-block"-->
<!--                :class="darkMode ? 'd-lg-block' : 'd-lg-none'"-->
<!--              />-->
<!--            </a>-->
<!--            <div-->
<!--              class="dropdown-menu dropdown-menu-animation dropdown-md border-radius-xl p-3 mt-0 mt-lg-3"-->
<!--              aria-labelledby="dropdownMenuAccount"-->
<!--            >-->
<!--              <div class="d-none d-lg-flex">-->
<!--                <div-->
<!--                  class="col-12 ps-0 d-flex justify-content-center flex-column"-->
<!--                >-->
<!--                  <ul class="list-group">-->
<!--                    <li-->
<!--                      class="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0"-->
<!--                    >-->
<!--                      <a-->
<!--                        id="dropdownSignUp"-->
<!--                        class="mb-1 dropdown-item border-radius-md ps-3 d-flex align-items-center"-->
<!--                      >-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >login</i-->
<!--                        >-->

<!--                        <span>Sign In</span>-->
<!--                        <img-->
<!--                          src="@/assets/img/down-arrow.svg"-->
<!--                          alt="down-arrow"-->
<!--                          class="arrow ms-auto"-->
<!--                        />-->
<!--                      </a>-->
<!--                      <div-->
<!--                        class="px-2 py-3 mt-0 dropdown-menu"-->
<!--                        aria-labelledby="dropdownSignIn"-->
<!--                      >-->
<!--                        <router-link-->
<!--                          class="mb-1 dropdown-item ps-3 border-radius-md"-->
<!--                          :to="{ name: 'Signin Basic' }"-->
<!--                        >-->
<!--                          <span>Basic</span>-->
<!--                        </router-link>-->
<!--                        <router-link-->
<!--                          class="mb-1 dropdown-item ps-3 border-radius-md"-->
<!--                          :to="{ name: 'Signin Cover' }"-->
<!--                        >-->
<!--                          <span>Cover</span>-->
<!--                        </router-link>-->
<!--                        <router-link-->
<!--                          class="mb-1 dropdown-item ps-3 border-radius-md"-->
<!--                          :to="{ name: 'Signin Illustration' }"-->
<!--                        >-->
<!--                          <span>Illustration</span>-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                    </li>-->
<!--                    <li-->
<!--                      class="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0"-->
<!--                    >-->
<!--                      <a-->
<!--                        id="dropdownSignUp"-->
<!--                        class="mb-1 dropdown-item border-radius-md ps-3 d-flex align-items-center"-->
<!--                      >-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >assignment</i-->
<!--                        >-->

<!--                        <span>Sign Up</span>-->
<!--                        <img-->
<!--                          src="@/assets/img/down-arrow.svg"-->
<!--                          alt="down-arrow"-->
<!--                          class="arrow ms-auto"-->
<!--                        />-->
<!--                      </a>-->
<!--                      <div-->
<!--                        class="px-2 py-3 mt-0 dropdown-menu"-->
<!--                        aria-labelledby="dropdownSignUp"-->
<!--                      >-->
<!--                        <router-link-->
<!--                          class="mb-1 dropdown-item ps-3 border-radius-md"-->
<!--                          :to="{ name: 'Signup Cover' }"-->
<!--                        >-->
<!--                          <span>Cover</span>-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                    </li>-->
<!--                    <li-->
<!--                      class="nav-item dropdown dropdown-hover dropdown-subitem list-group-item border-0 p-0"-->
<!--                    >-->
<!--                      <a-->
<!--                        id="dropdownSignUp"-->
<!--                        class="mb-1 dropdown-item border-radius-md ps-3 d-flex align-items-center"-->
<!--                      >-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >restart_alt</i-->
<!--                        >-->

<!--                        <span>Reset Password</span>-->
<!--                        <img-->
<!--                          src="@/assets/img/down-arrow.svg"-->
<!--                          alt="down-arrow"-->
<!--                          class="arrow ms-auto"-->
<!--                        />-->
<!--                      </a>-->
<!--                      <div-->
<!--                        class="px-2 py-3 mt-0 dropdown-menu"-->
<!--                        aria-labelledby="dropdownPasswordReset"-->
<!--                      >-->
<!--                        <router-link-->
<!--                          class="mb-1 dropdown-item ps-3 border-radius-md"-->
<!--                          :to="{ name: 'Reset Cover' }"-->
<!--                        >-->
<!--                          <span>Cover</span>-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row d-lg-none">-->
<!--                <div class="col-12 d-flex justify-content-center flex-column">-->
<!--                  <h6-->
<!--                    class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                  >-->
<!--                    <i class="material-icons opacity-6 me-2 text-md">login</i>-->
<!--                    Sign In-->
<!--                  </h6>-->
<!--                  <router-link-->
<!--                    :to="{ name: 'Signin Basic' }"-->
<!--                    class="dropdown-item border-radius-md"-->
<!--                    >Basic</router-link-->
<!--                  >-->
<!--                  <router-link-->
<!--                    :to="{ name: 'Signin Cover' }"-->
<!--                    class="dropdown-item border-radius-md"-->
<!--                    >Cover</router-link-->
<!--                  >-->
<!--                  <router-link-->
<!--                    :to="{ name: 'Signin Illustration' }"-->
<!--                    class="dropdown-item border-radius-md"-->
<!--                    >Illustration</router-link-->
<!--                  >-->
<!--                  <h6-->
<!--                    class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                  >-->
<!--                    <i class="material-icons opacity-6 me-2 text-md"-->
<!--                      >assignment</i-->
<!--                    >-->
<!--                    Sign Up-->
<!--                  </h6>-->
<!--                  <router-link-->
<!--                    :to="{ name: 'Signup Cover' }"-->
<!--                    class="dropdown-item border-radius-md"-->
<!--                    >Cover</router-link-->
<!--                  >-->
<!--                  <h6-->
<!--                    class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                  >-->
<!--                    <i class="material-icons opacity-6 me-2 text-md"-->
<!--                      >restart_alt</i-->
<!--                    >-->
<!--                    Reset Password-->
<!--                  </h6>-->
<!--                  <router-link-->
<!--                    :to="{ name: 'Reset Cover' }"-->
<!--                    class="dropdown-item border-radius-md"-->
<!--                    >Cover</router-link-->
<!--                  >-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li class="mx-2 nav-item dropdown dropdown-hover">-->
<!--            <a-->
<!--              id="dropdownMenuBlocks"-->
<!--              role="button"-->
<!--              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"-->
<!--              :class="[darkModes]"-->
<!--              data-bs-toggle="dropdown"-->
<!--              aria-expanded="false"-->
<!--            >-->
<!--              Applications-->
<!--              <img-->
<!--                :src="downArrWhite"-->
<!--                alt="down-arrow"-->
<!--                class="arrow ms-1"-->
<!--                :class="darkMode ? 'd-none' : 'd-lg-block d-none'"-->
<!--              />-->
<!--              <img-->
<!--                :src="downArrBlack"-->
<!--                alt="down-arrow"-->
<!--                class="arrow ms-1 d-block"-->
<!--                :class="darkMode ? 'd-lg-block' : 'd-lg-none'"-->
<!--              />-->
<!--            </a>-->
<!--            <div-->
<!--              class="p-3 mt-0 dropdown-menu dropdown-menu-animation dropdown-md dropdown-md-responsive border-radius-lg mt-lg-3"-->
<!--              aria-labelledby="dropdownMenuBlocks"-->
<!--            >-->
<!--              <div class="d-none d-lg-block">-->
<!--                <ul class="list-group">-->
<!--                  <li-->
<!--                    class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item"-->
<!--                  >-->
<!--                    <router-link-->
<!--                      class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                      :to="{ name: 'Kanban' }"-->
<!--                    >-->
<!--                      <div class="d-flex align-items-center text-dark">-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >widgets</i-->
<!--                        >-->
<!--                        Kanban-->
<!--                      </div>-->
<!--                    </router-link>-->
<!--                  </li>-->
<!--                  <li-->
<!--                    class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item"-->
<!--                  >-->
<!--                    <router-link-->
<!--                      class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                      :to="{ name: 'Wizard' }"-->
<!--                    >-->
<!--                      <div class="d-flex align-items-center text-dark">-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >import_contacts</i-->
<!--                        >-->
<!--                        Wizard-->
<!--                      </div>-->
<!--                    </router-link>-->
<!--                  </li>-->
<!--                  <li-->
<!--                    class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item"-->
<!--                  >-->
<!--                    <router-link-->
<!--                      class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                      :to="{ name: 'Data Tables' }"-->
<!--                    >-->
<!--                      <div class="d-flex align-items-center text-dark">-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >backup_table</i-->
<!--                        >-->
<!--                        DataTables-->
<!--                      </div>-->
<!--                    </router-link>-->
<!--                  </li>-->
<!--                  <li-->
<!--                    class="p-0 border-0 nav-item dropdown dropdown-hover dropdown-subitem list-group-item"-->
<!--                  >-->
<!--                    <router-link-->
<!--                      class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                      :to="{ name: 'Calendar' }"-->
<!--                    >-->
<!--                      <div class="d-flex align-items-center text-dark">-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >event</i-->
<!--                        >-->
<!--                        Calendar-->
<!--                      </div>-->
<!--                    </router-link>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--              &lt;!&ndash; responsive &ndash;&gt;-->
<!--              <div class="row d-lg-none">-->
<!--                <div class="col-md-12">-->
<!--                  <router-link-->
<!--                    class="py-2 ps-3 border-radius-md"-->
<!--                    :to="{ name: 'Kanban' }"-->
<!--                  >-->
<!--                    <div class="d-flex align-items-center text-dark">-->
<!--                      <i class="material-icons opacity-6 me-2 text-md"-->
<!--                        >widgets</i-->
<!--                      >-->
<!--                      Kanban-->
<!--                    </div>-->
<!--                  </router-link>-->
<!--                  <router-link-->
<!--                    class="py-2 ps-3 border-radius-md"-->
<!--                    :to="{ name: 'Wizard' }"-->
<!--                  >-->
<!--                    <div class="d-flex align-items-center text-dark">-->
<!--                      <i class="material-icons opacity-6 me-2 text-md"-->
<!--                        >import_contacts</i-->
<!--                      >-->
<!--                      Wizard-->
<!--                    </div>-->
<!--                  </router-link>-->
<!--                  <router-link-->
<!--                    class="py-2 ps-3 border-radius-md"-->
<!--                    :to="{ name: 'Data Tables' }"-->
<!--                  >-->
<!--                    <div class="d-flex align-items-center text-dark">-->
<!--                      <i class="material-icons opacity-6 me-2 text-md"-->
<!--                        >backup_table</i-->
<!--                      >-->
<!--                      DataTables-->
<!--                    </div>-->
<!--                  </router-link>-->
<!--                  <router-link-->
<!--                    class="py-2 ps-3 border-radius-md"-->
<!--                    :to="{ name: 'Calendar' }"-->
<!--                  >-->
<!--                    <div class="d-flex align-items-center text-dark">-->
<!--                      <i class="material-icons opacity-6 me-2 text-md">event</i>-->
<!--                      Calendar-->
<!--                    </div>-->
<!--                  </router-link>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li class="mx-2 nav-item dropdown dropdown-hover">-->
<!--            <a-->
<!--              id="dropdownMenuEcommerce"-->
<!--              role="button"-->
<!--              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"-->
<!--              :class="[darkModes]"-->
<!--              data-bs-toggle="dropdown"-->
<!--              aria-expanded="false"-->
<!--            >-->
<!--              Ecommerce-->
<!--              <img-->
<!--                :src="downArrWhite"-->
<!--                alt="down-arrow"-->
<!--                class="arrow ms-1"-->
<!--                :class="darkMode ? 'd-none' : 'd-lg-block d-none'"-->
<!--              />-->
<!--              <img-->
<!--                :src="downArrBlack"-->
<!--                alt="down-arrow"-->
<!--                class="arrow ms-1 d-block"-->
<!--                :class="darkMode ? 'd-lg-block' : ' d-lg-none'"-->
<!--              />-->
<!--            </a>-->
<!--            <div-->
<!--              class="p-3 mt-0 dropdown-menu dropdown-menu-animation dropdown-lg border-radius-xl mt-lg-3"-->
<!--              aria-labelledby="dropdownMenuEcommerce"-->
<!--            >-->
<!--              <div class="row d-none d-lg-block">-->
<!--                <div class="px-4 py-2 col-12">-->
<!--                  <div class="row">-->
<!--                    <div class="col-6 position-relative">-->
<!--                      <div-->
<!--                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center px-0"-->
<!--                      >-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >shopping_cart</i-->
<!--                        >-->
<!--                        Orders-->
<!--                      </div>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Order List' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Order List</span>-->
<!--                      </router-link>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Order Details' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Order Details</span>-->
<!--                      </router-link>-->
<!--                      <hr class="vertical dark" />-->
<!--                    </div>-->
<!--                    <div class="col-6 position-relative">-->
<!--                      <div-->
<!--                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center px-0"-->
<!--                      >-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >memory</i-->
<!--                        >-->
<!--                        Products-->
<!--                      </div>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'New Product' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">New Product</span>-->
<!--                      </router-link>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Edit Product' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Edit Product</span>-->
<!--                      </router-link>-->
<!--                      <router-link-->
<!--                        :to="{ name: 'Product Page' }"-->
<!--                        class="dropdown-item border-radius-md"-->
<!--                      >-->
<!--                        <span class="ps-3">Product Page</span>-->
<!--                      </router-link>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; responsive &ndash;&gt;-->
<!--              <div class="d-lg-none">-->
<!--                <div-->
<!--                  class="px-0 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                >-->
<!--                  <i class="material-icons opacity-6 me-2 text-md"-->
<!--                    >shopping_cart</i-->
<!--                  >-->
<!--                  Orders-->
<!--                </div>-->
<!--                <router-link-->
<!--                  :to="{ name: 'Order List' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Order List</router-link-->
<!--                >-->
<!--                <router-link-->
<!--                  :to="{ name: 'Order Details' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Order Details</router-link-->
<!--                >-->
<!--                <div-->
<!--                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                >-->
<!--                  <i class="material-icons opacity-6 me-2 text-md">store</i>-->
<!--                  General-->
<!--                </div>-->
<!--                <div-->
<!--                  class="px-0 mt-3 dropdown-header text-dark font-weight-bolder d-flex align-items-center"-->
<!--                >-->
<!--                  <i class="material-icons opacity-6 me-2 text-md">memory</i>-->
<!--                  Products-->
<!--                </div>-->
<!--                <router-link-->
<!--                  :to="{ name: 'New Product' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >New Product</router-link-->
<!--                >-->
<!--                <router-link-->
<!--                  :to="{ name: 'Edit Product' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Edit Product</router-link-->
<!--                >-->
<!--                <router-link-->
<!--                  :to="{ name: 'Product Page' }"-->
<!--                  class="dropdown-item border-radius-md"-->
<!--                  >Product Page</router-link-->
<!--                >-->
<!--              </div>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li class="mx-2 nav-item dropdown dropdown-hover">-->
<!--            <a-->
<!--              id="dropdownMenuDocs"-->
<!--              role="button"-->
<!--              class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"-->
<!--              :class="[darkModes]"-->
<!--              data-bs-toggle="dropdown"-->
<!--              aria-expanded="false"-->
<!--            >-->
<!--              Docs-->
<!--              <img-->
<!--                :src="downArrWhite"-->
<!--                alt="down-arrow"-->
<!--                class="arrow ms-1"-->
<!--                :class="darkMode ? 'd-none' : 'd-lg-block d-none'"-->
<!--              />-->
<!--              <img-->
<!--                :src="downArrBlack"-->
<!--                alt="down-arrow"-->
<!--                class="arrow ms-1 d-block"-->
<!--                :class="darkMode ? 'd-lg-block' : 'd-lg-none'"-->
<!--              />-->
<!--            </a>-->
<!--            <div-->
<!--              class="p-3 mt-0 dropdown-menu dropdown-menu-animation dropdown-lg mt-lg-3 border-radius-lg"-->
<!--              aria-labelledby="dropdownMenuDocs"-->
<!--            >-->
<!--              <div class="d-none d-lg-block">-->
<!--                <ul class="list-group">-->
<!--                  <li class="p-0 border-0 nav-item list-group-item">-->
<!--                    <a-->
<!--                      class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                      href="https://www.creative-tim.com/learning-lab/vue/overview/material-dashboard/"-->
<!--                    >-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >article</i-->
<!--                        >-->
<!--                        <h6-->
<!--                          class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0 mb-0"-->
<!--                        >-->
<!--                          Getting Started-->
<!--                        </h6>-->
<!--                      </div>-->
<!--                      <span class="text-sm opacity-8 ps-4"-->
<!--                        >All about overview, quick start, license and-->
<!--                        contents</span-->
<!--                      >-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <li class="p-0 border-0 nav-item list-group-item">-->
<!--                    <a-->
<!--                      class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                      href="https://www.creative-tim.com/learning-lab/vue/colors/material-dashboard/"-->
<!--                    >-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >grading</i-->
<!--                        >-->
<!--                        <h6-->
<!--                          class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0 mb-0"-->
<!--                        >-->
<!--                          Foundation-->
<!--                        </h6>-->
<!--                      </div>-->
<!--                      <span class="text-sm opacity-8 ps-4"-->
<!--                        >See our colors, icons and typography</span-->
<!--                      >-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <li class="p-0 border-0 nav-item list-group-item">-->
<!--                    <a-->
<!--                      class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                      href="https://www.creative-tim.com/learning-lab/vue/alerts/material-dashboard/"-->
<!--                    >-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >apps</i-->
<!--                        >-->
<!--                        <h6-->
<!--                          class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0 mb-0"-->
<!--                        >-->
<!--                          Components-->
<!--                        </h6>-->
<!--                      </div>-->
<!--                      <span class="text-sm opacity-8 ps-4"-->
<!--                        >Explore our collection of fully designed-->
<!--                        components</span-->
<!--                      >-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <li class="p-0 border-0 nav-item list-group-item">-->
<!--                    <a-->
<!--                      class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                      href="https://www.creative-tim.com/learning-lab/vue/datepicker/material-dashboard/"-->
<!--                    >-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >extension</i-->
<!--                        >-->
<!--                        <h6-->
<!--                          class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0 mb-0"-->
<!--                        >-->
<!--                          Plugins-->
<!--                        </h6>-->
<!--                      </div>-->
<!--                      <span class="text-sm opacity-8 ps-4"-->
<!--                        >Check how you can integrate our plugins</span-->
<!--                      >-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <li class="p-0 border-0 nav-item list-group-item">-->
<!--                    <a-->
<!--                      class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                      href="https://www.creative-tim.com/learning-lab/vue/utilities/material-dashboard/"-->
<!--                    >-->
<!--                      <div class="d-flex align-items-center">-->
<!--                        <i class="material-icons opacity-6 me-2 text-md"-->
<!--                          >swipe</i-->
<!--                        >-->
<!--                        <h6-->
<!--                          class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0 mb-0"-->
<!--                        >-->
<!--                          Utility Classes-->
<!--                        </h6>-->
<!--                      </div>-->
<!--                      <span class="text-sm opacity-8 ps-4"-->
<!--                        >For those who want flexibility, use our utility-->
<!--                        classes</span-->
<!--                      >-->
<!--                    </a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--              <div class="row d-lg-none">-->
<!--                <div class="col-md-12 g-0">-->
<!--                  <a-->
<!--                    class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                    href="../../pages/about-us.html"-->
<!--                  >-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      <i class="material-icons opacity-6 me-2 text-md"-->
<!--                        >article</i-->
<!--                      >-->
<!--                      <h6-->
<!--                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0"-->
<!--                      >-->
<!--                        Getting Started-->
<!--                      </h6>-->
<!--                    </div>-->
<!--                    <span class="text-sm ps-4"-->
<!--                      >All about overview, quick start, license and-->
<!--                      contents</span-->
<!--                    >-->
<!--                  </a>-->
<!--                  <a-->
<!--                    class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                    href="../../pages/about-us.html"-->
<!--                  >-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      <i class="material-icons opacity-6 me-2 text-md"-->
<!--                        >grading</i-->
<!--                      >-->
<!--                      <h6-->
<!--                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0"-->
<!--                      >-->
<!--                        Foundation-->
<!--                      </h6>-->
<!--                    </div>-->
<!--                    <span class="text-sm ps-4"-->
<!--                      >See our colors, icons and typography</span-->
<!--                    >-->
<!--                  </a>-->
<!--                  <a-->
<!--                    class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                    href="../../pages/about-us.html"-->
<!--                  >-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      <i class="material-icons opacity-6 me-2 text-md">apps</i>-->
<!--                      <h6-->
<!--                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0"-->
<!--                      >-->
<!--                        Components-->
<!--                      </h6>-->
<!--                    </div>-->
<!--                    <span class="text-sm ps-4"-->
<!--                      >Explore our collection of fully designed components</span-->
<!--                    >-->
<!--                  </a>-->
<!--                  <a-->
<!--                    class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                    href="../../pages/about-us.html"-->
<!--                  >-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      <i class="material-icons opacity-6 me-2 text-md"-->
<!--                        >extension</i-->
<!--                      >-->
<!--                      <h6-->
<!--                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0"-->
<!--                      >-->
<!--                        Plugins-->
<!--                      </h6>-->
<!--                      <span class="text-sm ps-4"-->
<!--                        >Check how you can integrate our plugins</span-->
<!--                      >-->
<!--                    </div>-->
<!--                  </a>-->
<!--                  <a-->
<!--                    class="py-2 dropdown-item ps-3 border-radius-md"-->
<!--                    href="../../pages/about-us.html"-->
<!--                  >-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      <i class="material-icons opacity-6 me-2 text-md">swipe</i>-->
<!--                      <h6-->
<!--                        class="dropdown-header text-dark font-weight-bolder d-flex align-items-center p-0"-->
<!--                      >-->
<!--                        Utility Classes-->
<!--                      </h6>-->
<!--                      <span class="text-sm ps-4"-->
<!--                        >All about overview, quick start, license and-->
<!--                        contents</span-->
<!--                      >-->
<!--                    </div>-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </li>-->
        </ul>
        <ul class="navbar-nav mx-2">
          <li class="nav-item">
            <Authenticate />
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";
import logo from "@/assets/img/kodaCapital/brand-dark-text.png";
import Authenticate from "@/components/Authenticate";

export default {
  name: "Navbar",
  components: {Authenticate},
  props: {
    btnBackground: {
      type: String,
      default: "bg-white"
    },
    isBlur: {
      type: String,
      default: ""
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      downArrWhite,
      downArrBlack,
      logo
    };
  },
  computed: {
    darkModes() {
      return {
        "text-dark": this.darkMode
      };
    }
  }
};
</script>
