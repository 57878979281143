<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    data-scroll="true"
    :class="isAbsolute ? 'mt-4' : 'mt-4'"
  >
    <div class="px-4 py-1 container-fluid">
<!--      <breadcrumbs :current-page="currentRouteName" :color="color" />-->
<!--      <div-->
<!--        id="navbar"-->
<!--        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0"-->
<!--        :class="isRTL ? 'px-0' : 'me-sm-4'"-->
<!--      >-->
<!--        <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">-->
<!--          <div class="sidenav-toggler-inner">-->
<!--            <i-->
<!--              class="sidenav-toggler-line"-->
<!--              :class="color ? 'bg-white' : ''"-->
<!--            ></i>-->
<!--            <i-->
<!--              class="sidenav-toggler-line"-->
<!--              :class="color ? 'bg-white' : ''"-->
<!--            ></i>-->
<!--            <i-->
<!--              class="sidenav-toggler-line"-->
<!--              :class="color ? 'bg-white' : ''"-->
<!--            ></i>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <Authenticate />
        </div>
        <ul class="navbar-nav justify-content-end">
<!--          <li class="nav-item ps-3 d-flex align-items-center">-->
<!--            <i class="material-icons" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"-->
<!--               :checked="this.$store.state.isDarkMode"-->
<!--               @click="darkMode"-->
<!--            >lightbulb-->
<!--            </i>-->
<!--          </li>-->
          <li class="nav-item ps-3 d-flex align-items-center">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="p-0 nav-link text-body lh-1"
              @click="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
<!--          <li class="px-3 nav-item d-flex align-items-center">-->
<!--            <a-->
<!--              class="p-0 nav-link lh-1"-->
<!--              :class="color"-->
<!--              @click="toggleConfigurator"-->
<!--            >-->
<!--              <i class="material-icons fixed-plugin-button-nav cursor-pointer">-->
<!--                settings-->
<!--              </i>-->
<!--            </a>-->
<!--          </li>-->
          <li
            class="nav-item dropdown ps-3 d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              id="dropdownMenuButton"
              href="#"
              class="p-0 nav-link lh-1"
              :class="[color, showMenu ? 'show' : '']"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="material-icons cursor-pointer"> notifications </i>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/kodaCapital/logo-emblem-512x512-nobg.png"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Mint Opens</span> 11/20
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        Sunday, November 6th
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import MaterialInput from "@/components/MaterialInput.vue";
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapState } from "vuex";
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";
import Authenticate from "@/components/Authenticate";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
    MaterialInput,
    Authenticate,
  },
  props: {
    minNav: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: "text-body",
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.minNav;
  },

  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    toggleSidebar() {
      this.navbarMinimize();
    },
    darkMode() {
      if (this.$store.state.isDarkMode) {
        this.$store.state.isDarkMode = false;
        deactivateDarkMode();
        return;
      } else {
        this.$store.state.isDarkMode = true;
        activateDarkMode();
      }
    },
  },
};
</script>
