<template>
  <footer class="py-3 footer bg-gradient-white">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div class="copyright text-center text-sm text-secondary text-lg-start">
            © {{ new Date().getFullYear() }}, made with
            <i class="fa fa-heart" aria-hidden="true"></i> by
            <a
                href=""
                class="font-weight-bold text-primary"
                target="_blank"
            >ProxLab</a
            >
          </div>
        </div>
        <div class="col-lg-6">
          <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <button
                  class="btn btn-sm btn-dark"
                  target="_blank"
                  v-on:click="addToken()"
                  disabled
              >
                Add $KCT
              </button>
            </li>
            <li class="nav-item">
              <a
                  href="https://discord.gg/c3VTkaHcWq"
                  class="nav-link text-white"
                  target="_blank"
              >Discord</a
              >
            </li>
            <li class="nav-item">
              <a
                  href="https://twitter.com/Kodacapitaldao"
                  class="nav-link text-white"
                  target="_blank"
              >Twitter</a
              >
            </li>
            <li class="nav-item">
              <a
                  href="https://docs.kodacapitaldao.xyz"
                  class="nav-link text-white"
                  target="_blank"
              >Docs</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import useMoralis from "@/composables/useMoralis";
import {getEllipsisTxt} from "@/helpers/formatters";
import {switchNetwork} from "@/helpers/networks";

export default {
  name: "AppFooter",
  setup() {
    const {
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    } = useMoralis();
    return {
      getEllipsisTxt,
      switchNetwork,
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    };
  },
  methods: {
    async addToken() {
      const tokenAddress = '0x00D1a09205bbD5A2EA35524aFafB41Aa2d5E423e';
      const tokenSymbol = 'PRXCTY';
      const tokenDecimals = 18;
      const tokenImage = 'https://storage.googleapis.com/proxcity-random/icon-192x192.png';

      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: tokenImage, // A string url of the token logo
            },
          },
        });

        if (wasAdded) {
          console.log('Thanks for your interest!');
        } else {
          console.log('Your loss!');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
