<template>
  <div class="input-group">
    <div v-if="isAuthenticated">
      <a-tooltip placement="bottom">
        <template #title>
          <span>Disconnect</span>
        </template>
        <button class="btn btn-dark mb-0 btn btn-sm me-1" @click="logout">
          {{ getEllipsisTxt(user.get("ethAddress"), 5) }}
        </button>
      </a-tooltip>
    </div>
    <div v-else-if="!isAuthenticated">
      <button class="btn btn-dark mb-0 btn btn-sm me-1" @click="() => authenticate()">
        Connect
      </button>
    </div>
  </div>
</template>

<script>
import { getEllipsisTxt } from "../helpers/formatters.js";
import useMoralis from "../composables/useMoralis";
export default {
  components: {},
  setup() {
    const {
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    } = useMoralis();

    return {
      getEllipsisTxt,
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    };
  },
};
</script>

<style></style>
